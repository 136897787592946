/* eslint-disable react/prop-types */
import React, { Component, Suspense } from 'react'
import { HashRouter, Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { useAuth } from './api/context/auth-context'
import './scss/style.scss'
import { useLocalStorage } from './utils/hooks/storage'
import { STORAGE_KEYS } from './utils/storage-keys'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import 'react-confirm-alert/src/react-confirm-alert.css'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

function PrivateRoute({ children, ...rest }) {
  const [user] = useLocalStorage(STORAGE_KEYS.user)

  console.log('User', user)

  let auth = useAuth()
  const location = useLocation()
  console.log(auth, 'auth')
  if (!user) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />
  }

  return children
}

class App extends Component {
  render() {
    return (
      <QueryClientProvider client={queryClient}>
        <HashRouter>
          <Suspense fallback={loading}>
            <Routes>
              <Route exact path="/login" name="Login Page" element={<Login />} />
              {/* <Route exact path="/register" name="Register Page" element={<Register />} /> */}
              <Route exact path="/404" name="Page 404" element={<Page404 />} />
              <Route exact path="/500" name="Page 500" element={<Page500 />} />
              {/* <Route path="/apps/email/*" name="Email App" element={<EmailApp />} /> */}
              {/* <PrivateRoute path="*" name="Home" element={<DefaultLayout />} /> */}
              <Route
                path="*"
                name="Home"
                element={
                  <PrivateRoute>
                    <DefaultLayout />
                  </PrivateRoute>
                }
              />
            </Routes>
          </Suspense>
          <ToastContainer />
        </HashRouter>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    )
  }
}

export default App
