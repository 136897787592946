/* eslint-disable react/prop-types */
import Axios from 'axios'
import React, { createContext, useContext, useMemo } from 'react'
import { STORAGE_KEYS } from 'src/utils/storage-keys'

export const AxiosContext = createContext(undefined)

const AxiosProvider = ({ children }) => {
  const axios = useMemo(() => {
    const axiosInstance = Axios.create({
      headers: {
        'Content-type': 'application/json',
      },
    })

    axiosInstance.interceptors.request.use((config) => {
      // Read token for anywhere, in this case directly from localStorage
      const token = localStorage.getItem(STORAGE_KEYS.token)
      if (token) {
        config.headers.Authorization = `Bearer ${token.replace(/(^"|"$)/g, '')}`
      }

      return config
    })

    axiosInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        const status = error?.response?.status
        if (status === 401 || status === 422) {
          localStorage.removeItem(STORAGE_KEYS.token)
          localStorage.removeItem(STORAGE_KEYS.refreshToken)
          localStorage.removeItem(STORAGE_KEYS.user)
          window.location.replace('/login')
        }
        throw error
      },
    )

    return axiosInstance
  }, [])

  return <AxiosContext.Provider value={axios}>{children}</AxiosContext.Provider>
}

export function useAxios() {
  return useContext(AxiosContext)
}

export default AxiosProvider
